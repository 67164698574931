export default [
  {
    title: 'Dashboards',
    route: 'dashboard-ecommerce',
    icon: 'HomeIcon',
  },
  {
    title: 'PPDB',
    route: 'apps-email',
    icon: 'UserPlusIcon',
    children: [
      {
        title: 'Data Calon Siswa',
        route: 'data-calon-siswa',
        icon: '',
      },
      {
        title: 'Jadwal Tes Masuk',
        route: 'data-jadwal-tes',
        icon: '',
      },
      {
        title: 'Pembayaran',
        route: 'data-pembayaran',
        icon: '',
      },
    ],
  },
  {
    title: 'Kesiswaan',
    route: '',
    icon: 'UserIcon',
    children: [
      {
        title: 'Buku Induk Siswa',
        route: '',
        icon: '',
        children: [
          {
            title: 'Data Siswa',
            route: 'data-siswa',
            icon: '',
          },
          {
            title: 'Data Orang Tua',
            route: 'data-orangtua',
            icon: '',
          },
          {
            title: 'Prestasi',
            route: 'data-prestasi',
            icon: '',
          },
          {
            title: 'Mutasi',
            route: 'data-mutasi',
            icon: '',
          },
          {
            title: 'Import Foto',
            route: 'import-foto',
            icon: '',
          },
          {
            title: 'Import Data',
            route: 'import-data',
            icon: '',
          },
        ],
      },
      {
        title: 'Ekstrakurikuler',
        route: '',
        icon: '',
        children: [
          {
            title: 'Master Data',
            route: 'data-ekstrakurikuler',
            icon: '',
          },
          {
            title: 'Peserta',
            route: 'peserta-ekstrakurikuler',
            icon: '',
          },
          {
            title: 'Absensi',
            route: 'absensi-ekstrakurikuler',
            icon: '',
          },
          {
            title: 'Jadwal & Instruktur',
            route: 'instruktur-ekstrakurikuler',
            icon: '',
          },
        ],
      },
      {
        title: 'Konseling',
        route: '',
        icon: '',
        children: [
          {
            title: 'Pelanggaran',
            route: 'data-pelanggaran',
            icon: '',
          },
          {
            title: 'Studi Kasus',
            route: 'data-studi-kasus',
            icon: '',
          },
          {
            title: 'Kunjungi Rumah',
            route: 'data-kunjungan-rumah',
            icon: '',
          },
        ],
      },
    ],
  },
  {
    title: 'Kepegawaian',
    route: null,
    icon: 'UsersIcon',
    children: [
      {
        title: 'Data Kepegawaian',
        route: null,
        icon: '',
        children: [
          {
            title: 'Data Kepegawaian',
            route: 'data-pegawai',
          },
          {
            title: 'Penugasan',
            route: 'data-penugasan',
          },
          {
            title: 'Sertifikasi',
            route: 'data-sertifikasi',
          },
          {
            title: 'Import Foto',
            route: 'import-foto-pegawai',
          },
          {
            title: 'Import Data',
            route: 'import-data-pegawai',
          },
        ],
      },
      {
        title: 'Kehadiran',
        route: null,
        icon: '',
        children: [
          {
            title: 'Data Kehadiran',
            route: 'data-kehadiran',
          },
          {
            title: 'Rekap Kehadiran',
            route: 'rekap-kehadiran',
          },
          {
            title: 'Rekap Pegawai',
            route: 'rekap-pegawai',
          },
          {
            title: 'Rekap Semester',
            route: 'rekap-semester',
          },
          {
            title: 'Import Data',
            route: 'import-data-kehadiran',
          },
        ],
      },
      {
        title: 'Absensi',
        children: [
          {
            title: 'Pengajuan Absensi',
            route: 'pengajuan-absensi',
          },
          {
            title: 'Kegiatan Sekolah',
            route: 'kegiatan-sekolah',
          },
        ],
      },
    ],
  },
  {
    title: 'Akademik',
    route: null,
    icon: 'CalendarIcon',
    children: [
      {
        title: 'Kompetensi Dasar',
        children: [
          {
            title: 'KD-1 Spiritual',
            route: 'data-kd1spiritual',
          },
          {
            title: 'KD-2 Sosial',
            route: 'data-kd2sosial',
          },
          {
            title: 'KD-3 Pengetahuan',
            route: 'data-kd3pengetahuan',
          },
          {
            title: 'KD-4 Keterampilan',
            route: 'data-kd4keterampilan',
          },
          {
            title: 'Import Data',
            route: null,
          },
        ],
      },
      {
        title: 'Perangkat Mengajar',
        children: [
          {
            title: 'Master Data',
            route: 'perangkat-mengajar',
          },
          {
            title: 'Program Guru',
            route: 'program-guru',
          },
          {
            title: 'RPP Guru',
            route: 'rpp-guru',
          },
        ],
      },
      {
        title: 'Mata Pelajaran',
        children: [
          {
            title: 'Master Data',
            route: 'mata-pelajaran',
          },
          {
            title: 'Detail Mapel',
            route: 'detail-mapel',
          },
          {
            title: 'Tipe Jam Belajar',
            route: 'tipe-jam-belajar',
          },
          {
            title: 'Jam Belajar',
            route: 'jam-belajar',
          },
          {
            title: 'Permintaan Mengajar',
            route: 'permintaan-mengajar',
          },
        ],
      },
      {
        title: 'Kelas',
        children: [
          {
            title: 'Master Data',
            route: 'data-kelas',
          },
          {
            title: 'Kenaikan Kelas',
            route: 'data-kenaikan-kelas',
          },
          {
            title: 'Transfer Siswa',
            route: 'transfer-siswa',
          },
        ],
      },
      {
        title: 'Jadwal KBM',
        children: [
          {
            title: 'Pembagian Jadwal',
            route: 'pembagian-jadwal',
          },
          {
            title: 'Generate Jadwal',
            route: 'generate-jadwal',
          },
          {
            title: 'Jadwal Mengajar',
            route: 'jadwal-mengajar',
          },
          {
            title: 'Jadwal Pelajaran',
            route: 'jadwal-mata-pelajaran',
          },
          {
            title: 'Tugas Sekolah',
            route: 'tugas-sekolah',
          },
        ],
      },
      {
        title: 'Agenda mengajar',
        children: [
          {
            title: 'Absensi Belajar',
            route: 'absensi-belajar',
          },
          {
            title: 'Agenda mengajar',
            route: 'agenda-mengajar',
          },
          {
            title: 'Tugas Sekolah',
            route: 'tugas',
          },
          {
            title: 'Jadwal Mengajar',
            route: 'jadwal',
          },
        ],
      },
      {
        title: 'Nilai',
        children: [
          {
            title: 'Harian',
            route: 'nilai-harian',
          },
          {
            title: 'Ujian',
            route: 'nilai-ujian',
          },
          {
            title: 'Keterampilan',
            route: 'nilai-keterampilan',
          },
          {
            title: 'Sikap',
            route: 'nilai-sikap',
          },
          {
            title: 'Fisik & Kesehatan',
            route: 'fisik-kesehatan',
          },
        ],
      },
      {
        title: 'Raport',
        children: [
          {
            title: 'Review Nilai Mapel',
            route: 'nilai-mapel',
          },
          {
            title: 'Review Nilai Akhir',
            route: 'nilai-akhir',
          },
          {
            title: 'Nilai Akhir & Saran',
            route: 'saran',
          },
          {
            title: 'Raport',
            route: 'raport',
          },
        ],
      },
      {
        title: 'Kalender Akademik',
        children: [
          {
            title: 'Kalender Akademik',
            route: 'kalender-akademik',
          },
          {
            title: 'Cetak Kalender Akademik',
            route: 'cetak-kalender-akademik',
          },
        ],
      },
    ],
  },
  {
    title: 'Absensi',
    icon: 'ClockIcon',
    children: [
      {
        title: 'Kehadiran',
        children: [
          {
            title: 'Data Kehadiran',
            route: 'absensi-data-kehadiran',
          },
          {
            title: 'Rekap Kehadiran',
            route: 'absensi-rekap-kehadiran',
          },
          {
            title: 'Rekap Bulanan',
            route: 'absensi-rekap-bulanan',
          },
          {
            title: 'Upload Data',
            route: 'absensi-upload-data',
          },
        ],
      },
      {
        title: 'Izin',
        children: [
          {
            title: 'Pengajuan Izin',
            route: 'pengajuan-izin',
          },
          {
            title: 'Rekap Izin Siswa',
            route: 'rekap-izin-siswa',
          },
          {
            title: 'Rekap Izin Kelas',
            route: 'rekap-izin-kelas',
          },
          {
            title: 'Rekap Izin Belajar',
            route: 'rekap-izin-belajar',
          },
        ],
      },
      {
        title: 'Presensi Belajar',
        children: [
          {
            title: 'Absen Belajar',
            route: 'pb-absen-belajar',
          },
          {
            title: 'Rekap Mapel',
            route: 'pb-rekap-mapel',
          },
          {
            title: 'Presentase KBM',
            route: 'pb-presentase-kbm',
          },
          {
            title: 'Upload Data',
            route: 'pb-upload-data',
          },
        ],
      },
      {
        title: 'Presensi Ekskul',
        children: [
          {
            title: 'Absen Ekskul',
            route: 'pe-absen-ekskul',
          },
          {
            title: 'Rekap Ekskul',
            route: 'pe-rekap-ekskul',
          },
          {
            title: 'Presentase Ekskul',
            route: 'pe-presentase-ekskul',
          },
        ],
      },
    ],
  },
  {
    title: 'Keuangan',
    icon: 'CreditCardIcon',
    children: [
      {
        title: 'Master Keuangan',
        children: [
          {
            title: 'Master SPP',
            route: 'master-keuangan',
          },
          {
            title: 'Generate SPP',
            route: 'generate-spp',
          },
        ],
      },
      {
        title: 'Pembayaran',
        children: [
          {
            title: 'SPP',
            route: 'pembayaran-spp',
          },
          {
            title: 'Ujian',
            route: 'pembayaran-ujian',
          },
          {
            title: 'Bimbel',
            route: 'pembayaran-bimbel',
          },
          {
            title: 'Lainnya',
            route: 'pembayaran-lainnya',
          },
          {
            title: 'Laporan',
            route: 'laporan-pembayaran',
          },
        ],
      },
      {
        title: 'Tabungan',
        children: [
          {
            title: 'Tabungan',
            route: 'tabungan',
          },
          {
            title: 'Laporan',
            route: 'laporan-tabungan',
          },
        ],
      },
    ],
  },
  {
    title: 'Penggajian',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Data gaji',
        children: [
          {
            title: 'Data Referensi',
            route: 'data-referensi',
          },
          {
            title: 'Gaji Tetap',
            route: 'gaji-tetap',
          },
          {
            title: 'Gaji Tidak Tetap',
            route: 'gaji-tidak-tetap',
          },
          {
            title: 'BPJS',
            route: 'bpjs',
          },
        ],
      },
      {
        title: 'Transaksi',
        children: [
          {
            title: 'Data Referensi',
            route: 'transaksi-data-referensi',
          },
          {
            title: 'Upload Data',
            route: 'transaksi-upload-data',
          },
          {
            title: 'Proses Gaji',
            route: 'transaksi-proses-gaji',
          },
        ],
      },
      {
        title: 'Laporan',
        children: [
          {
            title: 'Rekap gaji',
            route: 'laporan-rekap-gaji',
          },
          {
            title: 'Bank Transfer',
            route: 'laporan-bank-transfer',
          },
          {
            title: 'Slip Gaji',
            route: 'laporan-slip-gaji',
          },
        ],
      },
    ],
  },
  {
    title: 'Perpustakaan',
    icon: 'BookOpenIcon',
    children: [
      {
        title: 'Master Data',
        children: [
          {
            title: 'Anggota',
            route: 'perpus-anggota',
          },
          {
            title: 'Buku',
            route: 'perpus-buku',
          },
        ],
      },
      {
        title: 'Transaksi',
        children: [
          {
            title: 'Peminjaman',
            route: 'asset-peminjaman',
          },
          {
            title: 'Pengembalian',
            route: 'asset-pengembalian',
          },
          {
            title: 'Laporan',
            route: 'data-laporan',
          },
        ],
      },
      {
        title: 'Kartu Perpustakaan',
        route: 'perpus-perpustakaan',
      },
    ],
  },
  {
    title: 'Inventaris',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Master Inventaris',
        children: [
          {
            title: 'Data Inventaris',
            route: 'data-inventaris',
          },
          {
            title: 'Barang Masuk',
            route: 'data-barangmasuk',
          },
          {
            title: 'Barang Keluar',
            route: 'data-barangkeluar',
          },
        ],
      },
      {
        title: 'Master Asset',
        children: [
          {
            title: 'Data Asset',
            route: 'data-asset',
          },
          {
            title: 'Asset Masuk',
            route: 'data-assetmasuk',
          },
          {
            title: 'Asset Keluar',
            route: 'data-assetkeluar',
          },
          {
            title: 'Pemeliharaan',
            route: 'data-assetpemeliharaan',
          },
        ],
      },
      {
        title: 'Transaksi Asset',
        children: [
          {
            title: 'Peminjaman',
            route: 'asset-peminjaman',
          },
          {
            title: 'Pengembalian',
            route: 'asset-pengembalian',
          },
        ],
      },
      {
        title: 'Pengaturan',
        children: [
          {
            title: 'Umum',
            route: 'set-umum',
          },
          {
            title: 'Katalog Barang',
            route: 'set-katalog-barang',
          },
          {
            title: 'Penyimpanan',
            route: 'set-penyimpanan',
          },
        ],
      },
    ],
  },
  {
    title: 'Management Surat',
    icon: 'ClipboardIcon',
    children: [
      {
        title: 'Master Surat',
        route: 'data-mastersurat',
      },
      {
        title: 'Kategori Surat',
        route: 'data-kategorisurat',
      },
      {
        title: 'Transaksi',
        children: [
          {
            title: 'Surat Masuk',
            route: 'data-suratmasuk',
          },
          {
            title: 'Surat Keluar',
            route: 'data-suratkeluar',
          },
          {
            title: 'Generate Surat',
            route: 'data-generate',
          },
        ],
      },
      {
        title: 'Arsip',
        children: [
          {
            title: 'Surat Masuk',
            route: 'data-arsipmasuk',
          },
          {
            title: 'Surat Keluar',
            route: 'data-arsipkeluar',
          },
        ],
      },
    ],
  },
  {
    title: 'Informasi',
    icon: 'InfoIcon',
    route: 'dashoard-informasi',
  },
  {
    title: 'Master Pengaturan',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Akademik',
        children: [
          {
            title: 'Tahun Akademik',
            route: '',
          },
        ],
      },
      {
        title: 'Pegawai',
        children: [
          {
            title: 'Status Karyawan',
            route: '',
          },
          {
            title: 'Posisi Karyawan',
            route: '',
          },
          {
            title: 'Tipe Karyawan',
            route: '',
          },
        ],
      },
      {
        title: 'Role & Menu',
        children: [
          {
            title: 'Menu Management',
            route: 'menu-data',
          },
          {
            title: 'Role Management',
            route: 'role-data',
          },
          {
            title: 'Role To Menu',
            route: 'role-menu-data',
          },
          {
            title: 'Users Role',
            route: 'role-users-data',
          },
        ],
      },
      {
        title: 'Umum',
        children: [
          {
            title: 'Golongan Darah',
            route: 'set-golongandarah',
          },
          {
            title: 'Suku',
            route: 'set-suku',
          },
        ],
      },
    ],
  },
];
